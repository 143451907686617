// put antd css below
:global {
  body {
    font-family: 'Helvetica Neue',Helvetica,Tahoma,Arial,'PingFang SC','Source Han Sans CN','Source Han Sans','Source Han Serif','Hiragino Sans GB','WenQuanYi Micro Hei','Microsoft YaHei',sans-serif;
  }
  .ant-calendar-picker-input {
    height: 50px;
    border-radius: 8px;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: baseline;
  }
  .ant-select-selection {
    height: 50px;
    margin-right: 30px;
    border-radius: 8px;
  }
  .ant-select-selection__rendered {
    line-height: 50px;
  }
  .ant-calendar-footer-btn .ant-calendar-ok-btn {
    height: auto;
    padding: 4px 15px;
  }
}

@primary-color: #FAC109;@text-color: #333333;@font-size-base: 16px;@border-color-base: #EBEBEB;